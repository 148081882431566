<template>
  <v-container height="100" class="px-0">
    <h6 class="my-4">Үзэxийг xүссэн газруудxxx</h6>
         <v-row>
        <v-col cols="12" class="pt-0">
          <v-mapbox
            access-token="pk.eyJ1IjoiZ3JhbmRpdHh4ayIsImEiOiJja25mcDYxdHYycjRnMzFwaGx0ZWVwNGhpIn0.EBks4tBwxFPv66eqLJfTWQ"
            map-style="mapbox://styles/mapbox/satellite-streets-v11"
            :center="[106.9057, 47.8864]"
            :zoom="9.5"
            id="map"
            ref="map"
            height="100vh"
          ></v-mapbox>
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
import TripCardSimple from "./TripCardSimple.vue";
import mainUI from "./mainUI.vue"
export default {
  components: {
    TripCardSimple,
    mainUI
  },
  data() {
    const srcs = {
      1: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
      2: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
      3: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
      4: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
      5: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
    };
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      autoUpdate: true,
      friends: ["Sandra Adams", "Britta Holt"],
      isUpdating: false,
      name: "Midnight Crew",
      people: [
        { header: "Group 1" },
        { name: "Sandra Adams", group: "Group 1", avatar: srcs[1] },
        { name: "Ali Connors", group: "Group 1", avatar: srcs[2] },
        { name: "Trevor Hansen", group: "Group 1", avatar: srcs[3] },
        { name: "Tucker Smith", group: "Group 1", avatar: srcs[2] },
        { divider: true },
        { header: "Group 2" },
        { name: "Britta Holt", group: "Group 2", avatar: srcs[4] },
        { name: "Jane Smith ", group: "Group 2", avatar: srcs[5] },
        { name: "John Smith", group: "Group 2", avatar: srcs[1] },
        { name: "Sandra Williams", group: "Group 2", avatar: srcs[3] },
      ],
      title: "The summer breeze",
    };
  },
  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
  },
  methods: {
    remove(item) {
      const index = this.friends.indexOf(item.name);
      if (index >= 0) this.friends.splice(index, 1);
    },
    _goDetail() {
      console.log("xx");
      //this.$router.push({ name: "MyTrips", params: { tripId: 11 } });
      this.dialog = true;
    },
  },
   mounted() {
    var warehouseLocation = [-83.083, 42.363];
    var lastAtRestaurant = 0;
    var keepTrack = [];
    var pointHopper = {};

    // Add your access token
    mapboxgl.accessToken =
      "pk.eyJ1IjoiZ3JhbmRpdHh4ayIsImEiOiJja25mcDYxdHYycjRnMzFwaGx0ZWVwNGhpIn0.EBks4tBwxFPv66eqLJfTWQ";

    // Initialize a map
    var map = new mapboxgl.Map({
      container: "map", // container id
      style: "mapbox://styles/mapbox/light-v10", // stylesheet location
      center: warehouseLocation, // starting position
      zoom: 12, // starting zoom
    });

    var warehouse = turf.featureCollection([turf.point(warehouseLocation)]);

    // Create an empty GeoJSON feature collection for drop off locations
    var dropoffs = turf.featureCollection([]);

    // Create an empty GeoJSON feature collection, which will be used as the data source for the route before users add any new data
    var nothing = turf.featureCollection([]);

    map.on("load", function () {
      map.addLayer({
        id: "dropoffs-symbol",
        type: "symbol",
        source: {
          data: dropoffs,
          type: "geojson",
        },
        layout: {
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
          "icon-image": "marker-15",
        },
      });

      map.addLayer({
        id: "warehouse",
        type: "circle",
        source: {
          data: warehouse,
          type: "geojson",
        },
        paint: {
          "circle-radius": 20,
          "circle-color": "white",
          "circle-stroke-color": "#3887be",
          "circle-stroke-width": 3,
        },
      });

      // Create a symbol layer on top of circle layer
      map.addLayer({
        id: "warehouse-symbol",
        type: "symbol",
        source: {
          data: warehouse,
          type: "geojson",
        },
        layout: {
          "icon-image": "grocery-15",
          "icon-size": 1,
        },
        paint: {
          "text-color": "#3887be",
        },
      });

      map.addSource("route", {
        type: "geojson",
        data: nothing,
      });

      map.addLayer(
        {
          id: "routeline-active",
          type: "line",
          source: "route",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#3887be",
            "line-width": ["interpolate", ["linear"], ["zoom"], 12, 3, 22, 12],
          },
        },
        "waterway-label"
      );

      map.addLayer(
        {
          id: "routearrows",
          type: "symbol",
          source: "route",
          layout: {
            "symbol-placement": "line",
            "text-field": "▶",
            "text-size": ["interpolate", ["linear"], ["zoom"], 12, 24, 22, 60],
            "symbol-spacing": [
              "interpolate",
              ["linear"],
              ["zoom"],
              12,
              30,
              22,
              160,
            ],
            "text-keep-upright": false,
          },
          paint: {
            "text-color": "#3887be",
            "text-halo-color": "hsl(55, 11%, 96%)",
            "text-halo-width": 3,
          },
        },
        "waterway-label"
      );

      // Listen for a click on the map
      map.on("click", function (e) {
        // When the map is clicked, add a new drop off point
        // and update the `dropoffs-symbol` layer
        newDropoff(map.unproject(e.point));
        updateDropoffs(dropoffs);
      });
    });

    function newDropoff(coords) {
      // Store the clicked point as a new GeoJSON feature with
      // two properties: `orderTime` and `key`
      var pt = turf.point([coords.lng, coords.lat], {
        orderTime: Date.now(),
        key: Math.random(),
      });

      dropoffs.features.push(pt);
      pointHopper[pt.properties.key] = pt;

      // Make a request to the Optimization API

      axios.get(assembleQueryURL()).then(function (response) {
        console.log(response);
        // Create a GeoJSON feature collection
        var routeGeoJSON = turf.featureCollection([
          turf.feature(response.data.trips[0].geometry),
        ]);

        // If there is no route provided, reset
        if (!response.data.trips[0]) {
          routeGeoJSON = nothing;
        } else {
          // Update the `route` source by getting the route source
          // and setting the data equal to routeGeoJSON
          map.getSource("route").setData(routeGeoJSON);
        }

        //
        if (response.data.waypoints.length === 12) {
          window.alert(
            "Maximum number of points reached. Read more at docs.mapbox.com/api/navigation/#optimization."
          );
        }
      });
    }

    function updateDropoffs(geojson) {
      map.getSource("dropoffs-symbol").setData(geojson);
    }

    // Here you'll specify all the parameters necessary for requesting a response from the Optimization API
    function assembleQueryURL() {
      // Store the location of the truck in a variable called coordinates
      var coordinates = [warehouseLocation];
      var distributions = [];
      keepTrack = [warehouseLocation];

      // Create an array of GeoJSON feature collections for each point
      var restJobs = objectToArray(pointHopper);

      // If there are actually orders from this restaurant
      if (restJobs.length > 0) {
        // Check to see if the request was made after visiting the restaurant
        var needToPickUp =
          restJobs.filter(function (d) {
            return d.properties.orderTime > lastAtRestaurant;
          }).length > 0;

        // If the request was made after picking up from the restaurant,
        // Add the restaurant as an additional stop
        if (needToPickUp) {
          var restaurantIndex = coordinates.length;
          // Add the restaurant as a coordinate
          coordinates.push(warehouseLocation);
          // push the restaurant itself into the array
          keepTrack.push(pointHopper.warehouse);
        }

        restJobs.forEach(function (d) {
          // Add dropoff to list
          keepTrack.push(d);
          coordinates.push(d.geometry.coordinates);
          // if order not yet picked up, add a reroute
          if (needToPickUp && d.properties.orderTime > lastAtRestaurant) {
            distributions.push(
              restaurantIndex + "," + (coordinates.length - 1)
            );
          }
        });
      }

      // Set the profile to `driving`
      // Coordinates will include the current location of the truck,
      return (
        "https://api.mapbox.com/optimized-trips/v1/mapbox/driving/" +
        coordinates.join(";") +
        "?distributions=" +
        distributions.join(";") +
        "&overview=full&steps=true&geometries=geojson&source=first&access_token=" +
        mapboxgl.accessToken
      );
    }

    function objectToArray(obj) {
      var keys = Object.keys(obj);
      var routeGeoJSON = keys.map(function (key) {
        return obj[key];
      });
      return routeGeoJSON;
    }
  },
};
</script>